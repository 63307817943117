import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./ComponentDef";

const dinoPowder = {
    '900': '#130E0E',
    '800': '#302322',
    '700': '#5F4644',
    'A700': '#5F4644',
    '600': '#8F6966',
    '500': '#BE8C88',
    '400': '#DFAF9F',
    'A400': '#DFAF9F',
    '300': '#ECC3B2',
    '200': '#F8CDB8',
    'A200': '#F8CDB8',
    '100': '#FBE5DA',
    'A100': '#FBE5DA',
    '50': '#FDEFE8',
    '20': '#fcf3ee'
}

const powderTheme = createTheme({
        components: components,
        name: 'powder',
        palette: {
            primary: dinoPowder,
            secondary: dinoPowder,
        },
        typography: typography
    },
    huHU
)

export default powderTheme