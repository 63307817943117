import {format} from "date-fns";

const getSubdomain = () => {
    return window.location.host.split('.')[0];
}

const formatHuman = (date) => format(date, "yyyy-MM-dd")

const getQueryParams = () => {
    const query = window.location.search.replace('?', '').split('&');
    let queryDict = {}
    for (let q of query) {
        let kv = q.split('=')
        queryDict[kv[0]] = kv[1]
    }
    return queryDict
}

const getFormData = (form) => {
    const formData = new FormData(form)
    let object = {};
    formData.forEach((value, key) => {
        if (value !== "") object[key] = value
        if (value === "on") object[key] = true
    });
    return object;
}

const getFormDataNative = (form, extra=[]) => {
    let formData = new FormData(form);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

const getFormDataWithImage = (form, img, extra) => {
    let formData = new FormData(form)
    formData.append("image", img, img.name);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

const thousandSeparator = (num) => {
    if (num === undefined) return num
    if (num === null) return num
    if (num === '') return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const isEmailValid = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


export {getSubdomain, getQueryParams, getFormData, getFormDataNative, getFormDataWithImage, thousandSeparator, isEmailValid, formatHuman}