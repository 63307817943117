import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./ComponentDef";


const dinoBlue = {
    '900': '#09120D',
    '800': '#060E12',
    '700': '#0F232D',
    'A700': '#0F232D',
    '600': '#1F455A',
    '500': '#2E6887',
    '400': '#3D8AB4',
    'A400': '#3D8AB4',
    '300': '#59A6D1',
    '200': '#7FBADC',
    'A200': '#7FBADC',
    '100': '#A7D0E7',
    'A100': '#A7D0E7',
    '50': '#CFE5F2',
    '20': '#EBF4F9'
}

const blueTheme = createTheme({
        components: components,
        palette: {
            primary: dinoBlue,
            secondary: dinoBlue,
        },
        name: 'blue',
        typography: typography
    },
    huHU
)

export default blueTheme