import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material";

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BasicTabs = ({tabs}) => {
    const [value, setValue] = React.useState(0);
    const theme = useTheme()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    {tabs?.map((t, i) => <Tab key={`label_${i}`} label={<Typography component={'p'} variant='caption' sx={{color: theme.palette.primary[500]}}>{t?.name}</Typography>} {...a11yProps(i)} />)}
                </Tabs>
            </Box>
            {tabs?.map((t, i) => <CustomTabPanel key={`tab_${i}`} value={value} index={i}>
                {t?.content}
            </CustomTabPanel>)}
        </Box>
    );
}

export default BasicTabs