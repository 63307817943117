import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./ComponentDef";

const dinoPurple = {
    '900': '#0F0A0E',
    '800': '#261923',
    '700': '#4C3347',
    'A700': '#4C3347',
    '600': '#724C6A',
    '500': '#98658D',
    '400': '#AD84A4',
    'A400': '#AD84A4',
    '300': '#C1A3BB',
    '200': '#CFBACD',
    'A200': '#CFBACD',
    '100': '#CFBACD',
    'A100': '#CFBACD',
    '50': '#E6DBE5',
    '20': '#EEE7EE'
}

const purpleTheme = createTheme({
        components: components,
        name: 'purple',
        palette: {
            primary: dinoPurple,
            secondary: dinoPurple,
        },
        typography: typography
    },
    huHU)

export default purpleTheme