import {Backdrop, Box, CircularProgress, Stack, useTheme} from "@mui/material";
import dinoLogo from "../assets/logos/DinoBooking_felirat-01.png"
import Fade from '@mui/material/Fade';

const Loading = ({isLoading}) => {
    const t = useTheme()
    return <Fade in={isLoading} timeout={{enter: 0, exit: 500}}>
        <Backdrop
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1, background: 'white'}}
            open={isLoading}
        >
            <Box style={{width: '100%',}}>
                <Stack spacing={2} justifyContent='center' alignItems='center'>
                    <img src={dinoLogo} className='loading-logo' alt=""/>
                    <CircularProgress style={{color: t.palette.primary[700]}}/>
                </Stack>
            </Box>
        </Backdrop>
    </Fade>
}

export default Loading