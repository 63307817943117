import './App.scss';
import ServiceProvider from "./pages/ServiceProvider";
import Reserve from "./pages/Reserve";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {useState, useEffect} from "react";
import greenTheme from "./components/themes/GreenTheme";
import blueTheme from "./components/themes/BlueTheme";
import greyTheme from "./components/themes/GreyTheme";
import purpleTheme from "./components/themes/PurpleTheme";
import powderTheme from "./components/themes/PowderTheme";
import NotFound from "./pages/NotFound";
import {getSubdomain} from "./utils/Utils";
import useCallDataApi from "./hooks/data";
import SuccessfulReservation from "./pages/SuccessfulReservation";
import {Helmet} from "react-helmet";
import Simple from "./pages/Simple";
import ReservationResignation from "./pages/ReservationResignation";


function App() {
    const [theme, setTheme] = useState(greenTheme)
    const [subdomain, setSubdomain] = useState(getSubdomain())
    const {getData} = useCallDataApi('sp-businesses')

    const getColor = () => {
        getData(`get_color?subdomain=${subdomain}`)
            .then(r => setColor(r.theme))
            .catch(() => window.location.href = 'https://dinobooking.hu/')
    }

    const setColor = (color) => {
        const colorMap = {
            'blue': blueTheme,
            'grey': greyTheme,
            'green': greenTheme,
            'purple': purpleTheme,
            'powder': powderTheme,
        }
        setTheme(colorMap[color])
    }

    useEffect(() => {
        console.log('get color')
        getColor()
    }, [subdomain])


    return <ThemeProvider theme={theme}>
        <Helmet>
            <meta name="theme-color" content={theme.palette.primary[700]}/>
        </Helmet>
        <Router>
            <Switch>
                <Route path="/" component={ServiceProvider} exact/>
                <Route path="/book" component={Reserve} exact/>
                <Route path="/success" component={SuccessfulReservation} exact/>
                <Route path="/resignation/:slug" component={ReservationResignation} exact/>
                <Route path="/adattovabbitasi-nyilatkozat" component={Simple} exact/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    </ThemeProvider>
}

export default App;