import Base from "./Base";
import Avatar from "@mui/material/Avatar";
import coolDino from '../assets/dinos/019-cool.png';
import sadDino from '../assets/dinos/035-crying.png'
import {Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {decode} from "js-base64";
import {useQuery} from "../hooks/hooks";
import useCallDataApi from "../hooks/data";
import {getSubdomain, thousandSeparator} from "../utils/Utils";
import Loading from "../components/Loading";


const SuccessfulReservation = () => {
    const [reservation, setReservation] = useState({})
    const [redirectUrl, setRedirectUrl] = useState('/')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [subHeader, setSubHeader] = useState('')
    const [reservationStatus, setReservationStatus] = useState('SUCCESS')
    const {postData} = useCallDataApi('sp-reserve')
    const {getData} = useCallDataApi('sp-businesses')
    let query = useQuery()

    const result = {
        SUCCESS: {
            header: 'Sikeres foglalás',
            subHeader: 'Sikeresen lefoglalta az időpontot!',
            img: coolDino
        },
        FAIL: {
            header: 'Sikertelen tranzakció',
            subHeader: 'Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. ' +
                'Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, ' +
                'lépjen kapcsolatba kártyakibocsátó bankjával.',
            img: sadDino
        },
        CANCEL: {
            header: 'Megszakított fizetés',
            subHeader: 'A tranzakciót megszakította, az időpont lefoglalásához kérjük próbáld meg újra.',
            img: sadDino
        },
        TIMEOUT: {
            header: 'Időtúllépés',
            subHeader: 'A tranzakció időtúllépés miatt nem teljesült, kérjük próbálja meg újra.',
            img: sadDino
        },
    }

    const fillReservation = () => {
        if (query.get('r')) postData('check_simple_result/', {
            result: query.get('r'),
            signature: query.get('s'),
            subdomain: getSubdomain()
        })
            .then(r => {
                if (r.data.is_valid) {
                    const resp = JSON.parse(decode(query.get('r')));
                    setReservationStatus(resp.e)
                    setMessage(result[resp.e].header)
                    setSubHeader(`Tranzakció azonosító: ${resp.t}\n${result[resp.e].subHeader}`)
                }
                setReservation(r.data.data)
            })
            .finally(() => setLoading(false))
        else {
            setMessage('Sikeres foglalás')
            setSubHeader('Amint foglalását visszaigazolják e-mailben értesíteni fogjuk!')
            setReservation(JSON.parse(decode(query.get('data'))))
            setLoading(false)
            // console.log(JSON.parse(decode(query.get('data'))))
        }
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const redirectUser = () => {
        if (redirectUrl === '/') history.push('/')
        else window.location.href = redirectUrl
    }

    useEffect(() => {
        onTop()
        fillReservation()
        getData(`get_redirect/?subdomain=${getSubdomain()}`).then(r => setRedirectUrl(r?.redirect))
    }, [])

    const theme = useTheme()

    const history = useHistory()
    const primaryColor = {color: theme.palette.primary[600], fontWeight: '700'};
    const style = {color: '#1C2427', fontWeight: '700'};
    return <Base>
        <div className="success-container">
            <div className="success-container-message">
                <Stack direction="row" spacing={{xs: 2}}>
                    <Avatar src={result[reservationStatus].img} sx={{width: 100, height: 100}} style={{alignSelf: "center"}}/>
                    <Stack>
                        <Typography variant="headline5" gutterBottom style={primaryColor}>{message}</Typography>
                        <Typography variant="body1" style={{color: '#38474D'}}>{subHeader}</Typography>
                    </Stack>
                </Stack>
            </div>
            <div className="success-container-data">
                <Stack>
                    <Typography variant="headline6" align="center" style={primaryColor}>Foglalás adatai</Typography>
                    <table>
                        <tbody>
                        <tr>
                            <td><Typography variant="body2" style={style}>Szolgáltatás</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{reservation?.service_name}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Szolgáltató</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{reservation?.colleague_name}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Ár</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{thousandSeparator(reservation?.payed)} Ft</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Időpont</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{reservation?.date} | {reservation?.start_time} - {reservation?.end_time}</Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Stack>
            </div>
            <Button style={{marginTop: '20px', color: theme.palette.primary.main}} variant='outlined'
                    onClick={redirectUser}>Vissza a kezdőlapra</Button>
        </div>

        <Loading isLoading={loading}/>
    </Base>
}

export default SuccessfulReservation