import {Button, Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useContext, useEffect, useState} from "react";
import ConfigContext from "../context/ConfigContext";
import CircleButton from "../components/CircleButton";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import ReservationContext from "../context/ReservationContext";


const Service = ({service}) => {
    const {baseUrl} = useContext(ConfigContext)
    return <div style={{display: 'flex', gap: '20px'}}>
        <Avatar src={baseUrl + service?.image} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
        <div className="text-container">
            <Typography align="left" variant="subtitle1">{service.name}</Typography>
            <Typography align="left" variant="body2">{service.description}</Typography>
            <Typography align="left" variant="body1">{service.length} perc</Typography>
        </div>
    </div>
}


const SelectService = () => {
    const {getData} = useCallDataApi('sp-services')
    const {selected, next} = useContext(ReservationContext)
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const theme = useTheme()

    useEffect(() => {
        if (selected?.category) getData(`get_for_category/?category=${selected?.category?.id}`)
            .then(r => setServices(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.category])

    const buttonStyle = {
        color: theme.palette.primary[500],
        border: `2px solid ${theme.palette.primary[500]}`,
        display: {xs: 'flex', sm: 'flex', md: 'none'},
        '&:hover': {
            color: theme.palette.primary[500],
            border: `2px solid ${theme.palette.primary[500]}`,
            background: theme.palette.primary[100]
        }
    };

    const proceed = (c) => {
        history.push({
            pathname: '/book',
            search: `?c=${selected?.category?.id}&s=${c.id}`
        })
        next({...selected, service: c})
    }

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            {services.map((s, i) =>
                <Box className="data-select" key={`data_${i}`}>
                    <Stack direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}} spacing={{xs: 2}} sx={{justifyContent: 'space-between'}}>
                        <Service service={s}/>
                        <Button size='large' variant='outlined' endIcon={<NavigateNextIcon/>} sx={buttonStyle} onClick={() => proceed(s)}>Kiválasztom</Button>
                        <CircleButton sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}} icon={<NavigateNextIcon/>} onClick={() => proceed(s)}/>
                    </Stack>

                </Box>)}
        </Stack>

        <Loading isLoading={loading}/>
    </div>
}

export default SelectService