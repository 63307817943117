import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useTheme} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";



const DinoStepper = () => {
    const {steps, activeStep, back} = useContext(ReservationContext)
    const theme = useTheme()

    return (
        <Box sx={{width: '100%'}} className="reservation-stepper">
            <Stepper activeStep={activeStep} style={{color: theme.palette.primary[600]}}>
                {steps.map((label, index) => {
                    return (
                        <Step key={`step_${index}`} sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'primary.600', // circle color (COMPLETED)

                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: 'primary.600', // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                {
                                    color: '#fff', // Just text label (COMPLETED)
                                },
                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'common.white', // Just text label (ACTIVE)
                                },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: '#fff', // circle's number (ACTIVE)
                            },
                        }}>
                            <StepLabel/>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={back}
                        sx={{mr: 1}}
                        startIcon={<ArrowBackIosIcon/>}
                        style={{color: theme.palette.primary.main}}
                    >
                        Vissza
                    </Button>
                </Box>
            </React.Fragment>
        </Box>
    );
}

export default DinoStepper
