import {Button, Checkbox, Divider, FormControlLabel, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {useContext, useState} from "react";
import {decode} from "js-base64";
import {thousandSeparator} from "../utils/Utils";
import ReservationContext from "../context/ReservationContext";

const Selected = () => {
    const [accepted, setAccepted] = useState(false)
    const {selected, reserve, canProceed, extraItemData} = useContext(ReservationContext)


    const getDate = () => {
        if (selected?.date) return decode(selected?.date + '==')
        return '-'
    }

    const getCategoryName = () => {
        return selected?.category?.name ? selected?.category.name : '-';
    }

    const getServiceName = () => {
        return selected?.service?.name ? selected?.service.name : '-';
    }

    const getColleagueName = () => {
        return selected?.price?.colleague?.name ? selected?.price?.colleague.name : '-';
    }

    const getPriceName = () => {
        return selected?.price?.price ? thousandSeparator(selected?.price.price) + ' Ft' : '-';
    }

    const getSum = () => {
        let sum = 0
        for (let item of extraItemData) {
            sum += item?.quantity * item?.price
        }
        sum += selected?.price?.price
        return sum
    }

    const theme = useTheme()

    const colorPrimary = {color: theme.palette.primary[600]};
    const colorDark = {color: '#1C2427', fontWeight: '700'};
    return <div className="reservation-container">
        <Stack spacing={{xs: 1}}>
            <Typography variant="headline6" style={colorPrimary}>Foglalás adatai</Typography>
            <table>
                <tbody>
                <tr>
                    <td><Typography variant="body2" style={colorDark}>Kategória</Typography></td>
                    <td><Typography variant="body1" align="right" style={{...colorPrimary, fontWeight: '700'}}>{getCategoryName()}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant="body2" style={colorDark}>Szolgáltatás</Typography></td>
                    <td><Typography variant="body1" align="right" style={{...colorPrimary, fontWeight: '700'}}>{getServiceName()}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant="body2" style={colorDark}>Szolgáltató</Typography></td>
                    <td><Typography variant="body1" align="right" style={{...colorPrimary, fontWeight: '700'}}>{getColleagueName()}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant="body2" style={colorDark}>Ár</Typography></td>
                    <td><Typography variant="body1" align="right" style={{...colorPrimary, fontWeight: '700'}}>{getPriceName()}</Typography></td>
                </tr>
                <tr>
                    <td><Typography variant="body2" style={colorDark}>Időpont</Typography></td>
                    <td><Typography variant="body1" align="right" style={{...colorPrimary, fontWeight: '700'}}>{getDate()}</Typography></td>
                </tr>
                </tbody>
            </table>

            {extraItemData.length > 0 && <>
                <Typography variant="headline6" style={colorPrimary}>Extra tételek</Typography>

                <table>
                    <tbody>
                    {extraItemData.map(e => <tr key={`item-${e.id}`}>
                        <td><Typography variant="body2" style={colorDark}>{e?.name} x {e?.quantity}</Typography></td>
                        <td><Typography variant="body1" align="right"
                                        style={{
                                            ...colorPrimary,
                                            fontWeight: '700'
                                        }}>{thousandSeparator(e?.price * e?.quantity)} Ft</Typography></td>
                    </tr>)}


                    <tr>
                        <td><Typography variant="body2" style={colorDark}>Összesen</Typography></td>
                        <td><Typography variant="body1" align="right"
                                        style={{...colorPrimary, fontWeight: '700'}}>{thousandSeparator(getSum())} Ft</Typography></td>
                    </tr>
                    </tbody>
                </table>
            </>}

            <Divider/>

            <Stack sx={{gap: 2}}>
                <FormControlLabel
                    control={<Checkbox checked={accepted} onChange={(e) => setAccepted(e.target.checked)}/>}
                    label={<Typography variant='caption'>Elfogadom az <a style={{color: theme.palette.primary[700]}} target='_blank' href='https://dinobooking.hu/adatvedelem' rel="noreferrer">adatvédelmi nyilatkozatot</a> és amennyiben online fizetek a <a style={{color: theme.palette.primary[700]}} href="/adattovabbitasi-nyilatkozat" target='_blank'>SimplePay adattovábbítási nyilatkozatot</a></Typography>} style={{color: '#6F8E9A'}}/>
                <Button variant='contained' style={{color: '#fff'}} size='large' disabled={!accepted || !canProceed()} onClick={reserve} startIcon={<CalendarTodayIcon/>}>Időpont lefoglalása</Button>
            </Stack>

        </Stack>
    </div>
}

export default Selected