import Base from "./Base";
import Avatar from "@mui/material/Avatar";
import sadDino from '../assets/dinos/035-crying.png'
import {Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import {getSubdomain, thousandSeparator} from "../utils/Utils";
import Loading from "../components/Loading";
import {useSnackbar} from "notistack";


const Cancellable = (props) => {
    return <Stack marginTop={2} spacing={2} justifyContent="center" alignItems="center">
        <Typography variant="headline6">Biztosan lemondja a foglalást?</Typography>
        <Typography variant="body1">Ez a lépés nem vonható vissza!</Typography>
        <Stack direction="row" spacing={2}>
            <Button size="large" style={{color: "#fff"}} variant="contained" onClick={props.onClick}>Mégsem, vissza a
                kezdőlapra</Button>
            <Button size="large" style={{color: props.theme.palette.primary.main}} variant="outlined" onClick={props.onClick1}>Lemondás</Button>
        </Stack>
    </Stack>;
}

const NotCancellable = (props) => {
    return <Stack marginTop={2} spacing={2} justifyContent="center" alignItems="center">
        <Typography variant="headline6">Ezt az időpontot nem lehet lemondani!</Typography>
        <Typography variant="body1">A nem lemondhatóra állította ezt a szolgáltatást.</Typography>
        <Stack direction="row" spacing={2}>
            <Button size="large" style={{color: "#fff"}} variant="contained" onClick={props.onClick}>Vissza a kezdőlapra</Button>
        </Stack>
    </Stack>;
}


const ReservationResignation = () => {
    const [reservation, setReservation] = useState({})
    const [redirectUrl, setRedirectUrl] = useState('/')
    const [loading, setLoading] = useState(true)
    const {postData} = useCallDataApi('sp-reserve')
    const {getData} = useCallDataApi('sp-businesses')
    const params = useParams()
    const history = useHistory()
    const theme = useTheme()
    const snackbar = useSnackbar()

    const fillReservation = () => {
        postData('get_reservation_resignation/', {slug: params.slug})
            .then(r => setReservation(r?.data))
            .catch(() => redirectUser())
            .finally(() => {
                setLoading(false)
                // redirectUser()
            })
    }

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    const redirectUser = () => {
        if (redirectUrl === '/') history.push('/')
        else window.location.href = redirectUrl
    }

    const resign = () => {
        postData('resign_reservation/', {slug: params.slug}).then(r => {
            if (r?.data?.message === 'deleted') {
                snackbar.enqueueSnackbar('Foglalás lemondva, visszairányítunk a főoldalra!', {variant: 'success'})
                setTimeout(() => redirectUser(), 5000)
            } else {
                snackbar.enqueueSnackbar('A foglalás már törölve van, vagy nem sikerült a művelet!', {variant: 'warning'})
            }
        })
    }

    useEffect(() => {
        onTop()
        fillReservation()
        getData(`get_redirect/?subdomain=${getSubdomain()}`).then(r => setRedirectUrl(r?.redirect))
    }, [])


    const primaryColor = {color: theme.palette.primary[600], fontWeight: '700'};
    const style = {color: '#1C2427', fontWeight: '700'};
    return <Base>
        <div className="success-container">
            <div className="success-container-message">
                <Stack direction="row" spacing={{xs: 2}}>
                    <Avatar src={sadDino} sx={{width: 100, height: 100}} style={{alignSelf: "center"}}/>
                    {reservation?.cancellable ? <Cancellable onClick={redirectUser} theme={theme} onClick1={resign}/> : <NotCancellable onClick={redirectUser} theme={theme}/>}
                </Stack>
            </div>
            <div className="success-container-data">
                <Stack>
                    <Typography variant="headline6" align="center" style={primaryColor}>Foglalás adatai</Typography>
                    <table>
                        <tbody>
                        <tr>
                            <td><Typography variant="body2" style={style}>Szolgáltatás</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{reservation?.service_name}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Szolgáltató</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{reservation?.colleague_name}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Ár</Typography></td>
                            <td><Typography variant="body1" style={primaryColor}
                                            align="right">{thousandSeparator(reservation?.payed)} Ft</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" style={style}>Időpont</Typography></td>
                            <td><Typography variant="body1" style={primaryColor} align="right">{reservation?.date} | {reservation?.start_time} - {reservation?.end_time}</Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Stack>
            </div>


        </div>

        <Loading isLoading={loading}/>
    </Base>
}

export default ReservationResignation