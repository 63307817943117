import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./ComponentDef";


const dinoGreen = {
    '900': '#09120D',
    '800': '#182E21',
    '700': '#2F5C43',
    'A700': '#2F5C43',
    '600': '#478A64',
    '500': '#5EB885',
    '400': '#7EC69D',
    'A400': '#7EC69D',
    '300': '#9ED4B6',
    '200': '#BFE3CE',
    'A200': '#BFE3CE',
    '100': '#DFF1E7',
    'A100': '#DFF1E7',
    '50': '#EFF8F3',
    '20': '#F4FAF7'
}


const greenTheme = createTheme({
        components: components,
        name: 'green',
        palette: {
            primary: dinoGreen,
            secondary: dinoGreen,
        },
        typography: typography
    },
    huHU
)

export default greenTheme