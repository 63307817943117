import {Grid, Stack, useTheme} from "@mui/material";
import DinoCard from "./DinoCard";
import Typography from "@mui/material/Typography";
import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";
import PropTypes from "prop-types";


const Services = ({services, heading, text}) => {
    const {baseUrl} = useContext(ConfigContext)
    const theme = useTheme()

    return <div className='services-container' id="services">
        <div className="services-container__content">
            <Stack>
                <Typography variant='headline2' style={{color: theme.palette.primary[700]}} gutterBottom>{heading}</Typography>
                <Typography variant='subtitle1' gutterBottom style={{marginBottom: '48px', color: '#6F8E9A'}}>{text}</Typography>

                <Grid container spacing={6}>
                    {services.map((s, i) =>
                        <Grid
                            item
                            key={`service-card-${i}`}
                            xs={12}
                            md={6}
                            xl={6}>
                            <DinoCard
                                img={baseUrl + s.image}
                                title={s.name}
                                description={s.description}
                                category={s.category}
                                id={s.id}/>
                        </Grid>
                    )}
                </Grid>
            </Stack>

        </div>
    </div>
}

Services.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
    })),
    text: PropTypes.string
};

export default Services
