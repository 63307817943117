import Typography from "@mui/material/Typography";
import {Box, Button, Stack, useTheme} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";
import ConfigContext from "../context/ConfigContext";
import Avatar from "@mui/material/Avatar";
import {thousandSeparator} from "../utils/Utils";

const ExtraItem = ({extraItem}) => {
  const {baseUrl} = useContext(ConfigContext)
  const {plusItem, minusItem, getCurrentQuantityById} = useContext(ReservationContext)
  const theme = useTheme()
  const style = {color: theme.palette.primary[600]}

  return <div style={{display: 'flex', gap: '20px'}}>
    <Avatar src={baseUrl + extraItem?.image} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
    <Stack direction='row' justifyContent='space-between' sx={{width: '100%'}}>
      <div className="text-container">
        <Typography align="left" variant="subtitle1">{extraItem.name}</Typography>
        <Typography align="left" variant="body2">{extraItem.description}</Typography>
        <Typography align="left" variant="caption">{thousandSeparator(extraItem.price)} Ft</Typography>
      </div>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Button style={style} onClick={() => minusItem(extraItem)}>-</Button>
        <Box>{getCurrentQuantityById(extraItem.id)}</Box>
        <Button style={style} onClick={() => plusItem(extraItem)}>+</Button>
      </Stack>
    </Stack>
  </div>
}

const ExtraItemData = () => {
  const {extraItems} = useContext(ReservationContext)
  const theme = useTheme()
  const style = {color: theme.palette.primary[600]}

  return <div className="data-container-fill">
    <Typography variant="headline6" gutterBottom style={style}>Extra tételek</Typography>
    <form style={{marginTop: 10}}>
      <Stack spacing={1}>
        {extraItems?.map(e => <ExtraItem extraItem={e} key={`extra-item-${e?.id}`}/>)}
      </Stack>
    </form>
  </div>
}

export default ExtraItemData