const typography = {
    headline1: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "56px",
        lineHeight: "61px",
        '@media (max-width: 780px)': {
            fontSize: "40px"
        }
    },
    headline2: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "40px",
        lineHeight: "44px",
        '@media (max-width: 780px)': {
            fontSize: "30px"
        }
    },
    headline3: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "38px",
        lineHeight: "41px",
        '@media (max-width: 780px)': {
            fontSize: "28px"
        }
    },
    headline4: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "37px",
    },
    headline5: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "30px",
    },
    headline6: {
        fontFamily: "Archivo",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "26px",
    },
    body1: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '16px',
    },
    body2: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
    },
    subtitle1: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '25px',
    },
    subtitle2: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
    },
    caption: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '13px',
        letterSpacing: '0.01em',
        textTransform: 'uppercase'
    },
    button: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        textTransform: 'lowercase'
    },
    buttonNormal: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
    },
    overline: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '11px',
        textTransform: 'uppercase',
        letterSpacing: '0.015em'
    }
}

export default typography