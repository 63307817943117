import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SocialButton from "./SocialButton";

const DinoContact = ({heading, description, email, phone, address, facebook, instagram, twitter, youtube}) => {
    const theme = useTheme();

    const style = {
        background: `linear-gradient(135.23deg, ${theme.palette.primary[500]} 15.68%, ${theme.palette.primary[600]} 84.37%)`,
        color: theme.palette.secondary.main,
    }

    return <span className="dino-contact" style={style}>
        <div className="dino-hero-content">
            <Stack spacing={{xs: 1, sm: 2, md: 4}}>
                <Typography className="dino-hero-content__heading" variant='headline1'>{heading}</Typography>
                <Typography className="dino-hero-content__text" variant='body1' style={{color: '#FFF'}}>{description}</Typography>
                <Typography className="dino-hero-content__text" variant='body1' style={{color: '#FFF'}}>{email}</Typography>
                <Typography className="dino-hero-content__text" variant='body1' style={{color: '#FFF'}}>{phone}</Typography>
                <Typography className="dino-hero-content__text" variant='body1' style={{color: '#FFF'}}>{address}</Typography>
                <Stack direction="row" spacing={2}>
                    {facebook && <SocialButton onClick={() => window.open('https://facebook.com/' + facebook, '_blank')} socialIcon={<FacebookOutlinedIcon/>}/>}
                    {instagram && <SocialButton onClick={() => window.open('https://instagram.com/' + instagram, '_blank')} socialIcon={<InstagramIcon/>}/>}
                    {twitter && <SocialButton onClick={() => window.open('https://twitter.com/' + twitter, '_blank')} socialIcon={<TwitterIcon/>}/>}
                    {youtube && <SocialButton onClick={() => window.open('https://youtube.com/' + youtube, '_blank')} socialIcon={<YouTubeIcon/>}/>}
                </Stack>
            </Stack>
            </div>
    </span>
}

export default DinoContact