import Typography from "@mui/material/Typography";
import {Stack, TextField, useTheme} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";

const OtherData = () => {
    const {otherData, setOtherData} = useContext(ReservationContext)
    const theme = useTheme()
    const style = {color: theme.palette.primary[600]}
    const headingStyle = {color: theme.palette.primary[500]}

    return <div className="data-container-fill">
        <Typography variant="headline6" gutterBottom style={style}>További</Typography>
        <form style={{marginTop: 10}}>
            <Stack spacing={1}>
                <Typography variant='caption' sx={headingStyle}>megjegyzés</Typography>
                <TextField variant='standard' name="comment" multiline rows={3} value={otherData?.comment}
                           onChange={e => setOtherData({...otherData, comment: e.target.value})}/>
            </Stack>
        </form>
    </div>
}

export default OtherData