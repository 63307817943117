import ReserveBase from "../reserve/ReserveBase";
import Base from "./Base";
import {ReservationProvider} from "../context/ReservationContext";


const Reserve = () => {

    return <Base>
        <div className="booking-container">
            <ReservationProvider>
                <ReserveBase/>
            </ReservationProvider>
        </div>
    </Base>
}


export default Reserve
