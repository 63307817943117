import Typography from "@mui/material/Typography";
import {Box, Stack, useTheme} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";
import ErrorHandlingTextField, {validateNumber, validateRequired} from "../components/ErrorHandlingTextField";
import ErrorHandlingCheckbox from "../components/ErrorHandlingCheckbox";

const QuestionData = () => {
    const {questionData, setQuestionData, error, setError, questions} = useContext(ReservationContext)
    const theme = useTheme()
    const style = {color: theme.palette.primary[600]}
    const headingStyle = {color: theme.palette.primary[500]}

    const questionTypesMap = {
        '1': (props) => <ErrorHandlingTextField {...props} variant='standard'/>,
        '2': (props) => <ErrorHandlingTextField {...props} variant='standard'/>,
        '3': (props) => <Box><ErrorHandlingCheckbox {...props}/></Box>,
    }

    const createValidatorListForServiceQuestion = (q) => {
        const validatorList = []
        if (q?.answer_type !== '3' && q.required) validatorList.push(validateRequired);
        if (q?.answer_type === '2') validatorList.push(validateNumber);
        return validatorList
    }

    return <div className="data-container-fill">
        <Typography variant="headline6" gutterBottom style={style}>Extra kérdések</Typography>
        <form style={{marginTop: 10}}>
            <Stack spacing={1}>
                {questions?.map((q, i) => <Stack spacing={1} key={`question_${i}`}>
                    <Typography variant='caption' sx={headingStyle}>{q.question}{q.required && '*'}</Typography>
                    {questionTypesMap[q.answer_type]({
                        check: v => setError({...error, [`q_${q.id}`]: v}),
                        validators: createValidatorListForServiceQuestion(q),
                        name: `q_${q.id}`,
                        value: questionData[`q_${q.id}`] || '',
                        onValChange: e => setQuestionData({
                            ...questionData, [`q_${q.id}`]: q.answer_type === '3' ? e.target.checked : e.target.value
                        })
                    })}
                </Stack>)}
            </Stack>
        </form>
    </div>
}

export default QuestionData