import React from 'react';
import whiteLogo from '../assets/logos/DinoBooking_logo_oldalt_felirat-04.png' ;
import { useTheme } from "@mui/material";


const PromoBar = () => {
    const theme = useTheme();

    const style = {
        background: theme.palette.primary[700],
        color: theme.palette.secondary,
    }

    return (
        <div className="promo-bar" style={style}>
            <span className="powered-by">POWERED BY</span>
            <span><img src={whiteLogo} alt="Dinobooking logo" className="logo"/></span>
        </div>
    );
}

export default PromoBar;