import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export function useAuthHeader() {
    return {
        unauthenticatedHeaders: {
            "Content-Type": "application/json",
        },
    };
}

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}