import {Checkbox} from "@mui/material";
import {useState} from "react";

const ErrorHandlingCheckbox = ({validators, onValChange, check, ...rest}) => {
    const [error, setError] = useState('')

    const validate = (val) => {
        setError('')
        for (let validator of validators) {
            const validatedResult = validator(val)
            if (!validatedResult.result) {
                if (check) check(true)
                return setError(validatedResult.error)
            }
        }
        if (check) check(false)
    }

    return <Checkbox error={error} {...rest} onChange={e => {
        onValChange(e)
        validate(e.target.checked)
    }}/>
}

export default ErrorHandlingCheckbox