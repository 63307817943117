import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DinoIconButton from "./DinoButton";
import {createTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";


const DinoHero = ({heading, description}) => {
    const theme = useTheme();

    const style = {
        background: `linear-gradient(135.23deg, ${theme.palette.primary[500]} 15.68%, ${theme.palette.primary[600]} 84.37%)`,
        color: theme.palette.secondary,
    }

    const buttonTheme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.main,
                        backgroundColor: '#fff',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#fff'
                        },
                        borderRadius: 30,
                        textTransform: 'lowercase',
                        width: '50%',
                        fontFamily: 'Archivo',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '18px',
                        padding: '15px 16px 15px 12px',
                        textDecoration: 'none'
                    }
                }
            }
        },
    })

    return <span className="dino-hero" style={style}>
        <div className="dino-hero-content">
            <Stack spacing={{xs: 1, sm: 2, md: 4}}>
                <Typography className="dino-hero-content__heading" variant='headline1'>{heading}</Typography>
                <Typography className="dino-hero-content__text" variant='subtitle1'>{description}</Typography>
                <Link to={"/book"} style={{textDecoration: 'none'}}>
                    <DinoIconButton className="dino-hero-content__button"
                                    icon={<CalendarTodayIcon/>}
                                    theme={buttonTheme}
                                    text='Időpont foglalás'/>
                </Link>
            </Stack>
            </div>
    </span>
}

export default DinoHero