import Typography from "@mui/material/Typography";
import {Grid, Stack, useTheme} from "@mui/material";
import ErrorHandlingTextField, {validateEmail, validateRequired} from "../components/ErrorHandlingTextField";
import ErrorHandlingPhoneInput, {
    validatePhoneNumber,
    validatePhoneRequired
} from "../components/ErrorHandlingPhoneInput";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";

const PersonalData = () => {
    const theme = useTheme()
    const {personalData, setPersonalData, error, setError} = useContext(ReservationContext)
    const style = {color: theme.palette.primary[600]}
    const headingStyle = {color: theme.palette.primary[500]}
    
    return <div className="data-container-fill">
        <Typography variant="headline6" style={style} gutterBottom>Személyes adatok</Typography>
        <form style={{marginTop: 10}}>
            <Grid container spacing={{xs: 2}}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <Typography variant='caption' sx={headingStyle}>Vezetéknév*</Typography>
                        <ErrorHandlingTextField check={v => setError({...error, last_name: v})}
                                                validators={[validateRequired]} value={personalData?.last_name}
                                                variant='standard' name="last_name"
                                                onValChange={e => setPersonalData({...personalData, last_name: e.target.value})}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <Typography variant='caption' sx={headingStyle}>Keresztnév*</Typography>
                        <ErrorHandlingTextField check={v => setError({...error, first_name: v})}
                                                validators={[validateRequired]} value={personalData?.first_name}
                                                variant='standard' name="first_name"
                                                onValChange={e => setPersonalData({...personalData, first_name: e.target.value})}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <Typography variant='caption' sx={headingStyle}>Email*</Typography>
                        <ErrorHandlingTextField check={v => setError({...error, email: v})}
                                                validators={[validateRequired, validateEmail]} value={personalData?.email}
                                                variant='standard' name="email"
                                                onValChange={e => setPersonalData({...personalData, email: e.target.value})}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <Typography variant='caption' sx={headingStyle}>Telefonszám*</Typography>
                        <ErrorHandlingPhoneInput
                            defaultCountry='hu'
                            variant='standard'
                            check={v => setError({...error, phone: v})}
                            value={personalData?.phone}
                            validators={[validatePhoneRequired, validatePhoneNumber]}
                            onValChange={e => setPersonalData({...personalData, phone: e})}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </form>
    </div>
}

export default PersonalData