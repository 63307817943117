import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import DinoIconButton from "./DinoButton";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {createTheme} from "@mui/material/styles";
import {useTheme} from "@mui/material";
import {useHistory} from "react-router-dom";


const DinoCard = ({img, title, description, id, category}) => {
    const theme = useTheme()
    const history = useHistory()

    const buttonTheme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: theme.palette.primary[500],
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        borderRadius: 30,
                        textTransform: 'capitalize',
                        width: 'fit-content',
                        fontFamily: 'Archivo',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '18px',
                        padding: '15px 16px 15px 12px'
                    }
                }
            }
        },
    })

    return (
        <Card style={{background: '#fff', borderRadius: '16px'}}>
            <CardMedia
                component="img"
                alt={title}
                height="320"
                image={img}
            />
            <CardContent style={{paddingTop: '32px', paddingLeft: '32px'}}>
                <Typography gutterBottom variant="headline6" component="div">
                    {title}
                </Typography>
                <Typography variant="body1" style={{color: '#536B74'}}>
                    {description}
                </Typography>
            </CardContent>
            <CardActions style={{paddingBottom: '32px', paddingLeft: '32px'}}>
                <DinoIconButton onClick={() => history.push({
                    pathname: '/book',
                    search: `?c=${category}&s=${id}`
                })} icon={<CalendarTodayIcon/>} text='Időpont foglalás' theme={buttonTheme}/>
            </CardActions>
        </Card>
    );
}

export default DinoCard
