import DinoAppBar from "../components/DinoAppBar";
import DinoFooter from "../components/DinoFooter";
import React, {useEffect, useState} from "react";
import {getSubdomain} from "../utils/Utils";
import useCallDataApi from "../hooks/data";
import {Helmet} from "react-helmet";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

const Base = ({children}) => {
    const {getData: fetchBusiness} = useCallDataApi('sp-businesses')
    const {getData: fetchWorkHours} = useCallDataApi('sp-work-hours')
    const [homePageData, setHomePageData] = useState({})
    const [workHours, setWorkHours] = useState({})

    const getHomePage = () => {
        const subdomain = getSubdomain()
        fetchBusiness(subdomain).then(r => setHomePageData(r))
        fetchWorkHours(`/get_for_business/?business=${subdomain}`).then(r => setWorkHours(r))
    }

    useEffect(() => {
        getHomePage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const theme = useTheme()

    return <>
        <Helmet>
            <meta
                name="description"
                content={homePageData?.hero_text}
            />
            <meta
                name="title"
                content={homePageData?.hero_heading}
            />
        </Helmet>
        {!homePageData?.only_links && <DinoAppBar name={homePageData?.public_name} logo={homePageData?.public_logo}/>}
        {children}
        <DinoFooter email={homePageData?.public_email}
                    phone={homePageData?.public_phone}
                    address={homePageData?.public_address}
                    logo={homePageData?.public_logo}
                    name={homePageData?.public_name}
                    workHours={workHours}
                    facebook={homePageData?.facebook}
                    instagram={homePageData?.instagram}
                    youtube={homePageData?.youtube}
                    twitter={homePageData?.twitter}
                    payment_on={homePageData?.payment_on}
                    onlyLinks={homePageData?.only_links}/>
        <div className="promo-footer" style={{background: theme.palette.primary[700]}}>
            <Typography variant='caption' noWrap>DESIGNED BY <a href="https://vallio.studio/">VALLIO STUDIO</a></Typography>
            <Typography variant='caption' noWrap>CREATED BY <a href="https://trilogic.hu/">TRILOGIC</a></Typography>
        </div>
    </>
}

export default Base