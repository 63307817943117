import Carousel from "react-material-ui-carousel";
import React, {useContext} from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ConfigContext from "../context/ConfigContext";

const DinoCarousel = ({images}) => {
    const {baseUrl} = useContext(ConfigContext)
    return <Carousel
        className='dino-carousel'
        fullHeightHover={true}     // We want the nav buttons wrapper to only be as big as the button element is
        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
                borderRadius: 0
            }
        }}
        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
                bottom: "0",
                top: "unset",
            }
        }}
        NextIcon={<NavigateNextIcon/>}             // Change the "inside" of the next button to "next"
        PrevIcon={<NavigateBeforeIcon/>}             // Change the "inside of the prev button to "prev"
    >
        {
            images.map((item, i) => <div key={`image_${i}`} style={{backgroundImage: `url(${baseUrl + item?.image})`, backgroundSize: 'cover', backgroundPosition: '50% 50%', width: '100%', height: '650px'}}/>)
        }
    </Carousel>;
}

export default DinoCarousel
