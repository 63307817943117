import {Grid, Stack, useTheme} from "@mui/material";
import DinoStepper from "../components/Stepper";
import Selected from "./Selected";
import SelectCategory from "./SelectCategory";
import SelectService from "./SelectService";
import SelectPrice from "./SelectPrice";
import SelectDate from "./SelectDate";
import FillData from "./FillData";
import {useContext} from "react";
import Typography from "@mui/material/Typography";

import Loading from "../components/Loading";
import ReservationContext from "../context/ReservationContext";


const ReserveBase = () => {
    const theme = useTheme()
    const {loading, activeStep} = useContext(ReservationContext)

    const stepMap = [
        <SelectCategory/>,
        <SelectService/>,
        <SelectPrice/>,
        <SelectDate/>,
        <FillData/>,
    ]

    const textMap = [
        {heading: 'Szolgáltatás kategória', text: 'Kérjük válasszon az alábbi kategóriák közül'},
        {heading: 'Szolgáltatás', text: 'Kérjük válasszon az alábbi szolgáltatások közül'},
        {heading: 'Kollégáink', text: 'Kérjük válasszon kollégáink közül'},
        {heading: 'Időpont', text: 'Kérjük válasszon időpontot'},
        {heading: 'Személyes adatok', text: 'Kérjük adja meg adatait'},
    ]

    return <>
        <Stack>
            <Typography variant="headline2" style={{color: theme.palette.primary[600]}} gutterBottom>{textMap[activeStep].heading}</Typography>
            <Typography variant="subtutle1" style={{color: '#6F8E9A', fontWeight: '700'}} gutterBottom>{textMap[activeStep].text}</Typography>
        </Stack>
        <DinoStepper/>

        <Grid container spacing={6}>
            <Grid item xs={12} md={7}>
                {stepMap[activeStep]}
            </Grid>
            <Grid item xs={12} md={5}>
                <Selected />
            </Grid>
        </Grid>

        <Loading isLoading={loading}/>
    </>
}

export default ReserveBase