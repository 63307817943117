const components = {
    MuiStepConnector: {
        styleOverrides: {
            root: {
                '&.Mui-disabled': {
                    '.MuiStepConnector-line': {
                        border: '3px solid #dcdcdc',
                        borderRadius: '4px',
                    },
                },
                '&.Mui-active': {
                    '.MuiStepConnector-line': {
                        border: '3px solid',
                        borderRadius: '4px',
                    },
                },
                '&.Mui-completed': {
                    '.MuiStepConnector-line': {
                        border: '3px solid',
                        borderRadius: '4px',
                    },
                },
            },
        },
    },
    MuiStepLabel: {
        styleOverrides: {
            label: {
                fontFamily: 'Archivo, sans-serif', // Change the font for MuiStepLabel
                fontWeight: '700'
            },
            root: {
                '&.Mui-disabled': {
                    '.MuiSvgIcon-root': {
                        color: '#dcdcdc',

                        '.MuiStepIcon-text': {
                            color: '#fff',
                        }
                    }
                },
            }
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                width: 36, // Set the width of the circle
                height: 36, // Set the height of the circle
            },
            // text: {
            //     fill: 'white', // Change the color of the number inside the circle
            // },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                color: '#FFFFFF',
                borderRadius: 30,
            }
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 20,
                backgroundColor: '#F3F5F7',

            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                // Add your font definition for helper text here
                fontFamily: 'Archivo, sans-serif',
                textTransform: 'none',
                paddingLeft: 10
                // fontSize: 14,
                // color: '#616161', // Choose your desired color
            },
        },
    },
    MuiInput: {
        styleOverrides: {
            root: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
                '&:hover': {
                    content: 'none',
                },
                '&.Mui-error': {
                    background: '#ffbaba',
                    transition: 'background .4s ease'
                },
            }
        }
    },
}

export {components}