import {Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import SocialButton from "./SocialButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";
import Avatar from "@mui/material/Avatar";
import greenLogo from "../assets/logos/colored-logos/DinoBooking_logo_oldalt_felirat-02-green.png"
import greyLogo from "../assets/logos/colored-logos/DinoBooking_logo_oldalt_felirat-02-grey.png"
import powderLogo from "../assets/logos/colored-logos/DinoBooking_logo_oldalt_felirat-02-powder.png"
import purpleLogo from "../assets/logos/colored-logos/DinoBooking_logo_oldalt_felirat-02-pink.png"
import blueLogo from "../assets/logos/colored-logos/DinoBooking_logo_oldalt_felirat-02-blue.png"
import simpleLogo from '../assets/simplepay_200x50.png'
import {HashLink} from "react-router-hash-link";

const ColoredImage = (color) => {
    const style = {alignSelf: 'center', width: '80%', maxWidth: "150px"}
    const logoMap = {
        blue: blueLogo,
        green: greenLogo,
        grey: greyLogo,
        powder: powderLogo,
        purple: purpleLogo,
    }
    return <img src={logoMap[color.color]} style={style} alt='Dinobooking logo'/>
}

const DinoFooter = ({name, address, phone, email, logo, workHours, facebook, instagram, twitter, youtube, payment_on, onlyLinks}) => {
    const theme = useTheme()
    const {baseUrl} = useContext(ConfigContext)
    const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
    ]
    const daysHU = {
        monday: 'hétfő',
        tuesday: 'kedd',
        wednesday: 'szerda',
        thursday: 'csütörtök',
        friday: 'péntek',
        saturday: 'szombat',
        sunday: 'vasárnap'
    }

    const createOpeningHours = () => {
        const obj = {}
        const ret = {}
        for (let day of days) {
            obj[workHoursForDay(day)] = []
        }
        for (let day of days) {
            obj[workHoursForDay(day)].push(daysHU[day])
        }
        Object.keys(obj).forEach(key => {
            ret[obj[key].join(', ')] = key;
        });
        return ret
    }

    const workHoursForDay = (day) => {
        if (!workHours.hasOwnProperty(day)) return 'zárva'
        if (workHours[day].length === 0) return 'zárva'
        let res = []
        for (let hours of workHours[day]) {
            res.push(hours?.start + '-' + hours?.end)
        }
        return res.join(' ')
    }

    const open = createOpeningHours()

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer">
        <div className="dino-footer-content">
            <Grid container spacing={12}>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Kontakt</Typography>
                        <Typography variant="buttonNormal" style={primaryColor}>{address}</Typography>
                        <Typography variant="button" style={primaryColor}>{phone}</Typography>
                        <Typography variant="button" style={primaryColor}>{email}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {facebook && <SocialButton onClick={() => window.open('https://facebook.com/' + facebook, '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>}
                        {instagram && <SocialButton onClick={() => window.open('https://instagram.com/' + instagram, '_blank')} inverted socialIcon={<InstagramIcon/>}/>}
                        {twitter && <SocialButton onClick={() => window.open('https://twitter.com/' + twitter, '_blank')} inverted socialIcon={<TwitterIcon/>}/>}
                        {youtube && <SocialButton onClick={() => window.open('https://youtube.com/' + youtube, '_blank')} inverted socialIcon={<YouTubeIcon/>}/>}
                    </Stack>
                </Grid>
                {!onlyLinks && <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Szekciók</Typography>
                        <HashLink to="/#home" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Kezdőlap</Typography>
                        </HashLink>
                        <HashLink to="/#services" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Szolgáltatások</Typography>
                        </HashLink>
                        <HashLink to="/#colleagues" scroll={el => scrollWithOffset(el)}
                                  style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Kollégáink</Typography>
                        </HashLink>
                        <HashLink to="/#contact-us" scroll={el => scrollWithOffset(el)}
                                  style={{textDecoration: 'none'}}>
                            <Typography variant="button" style={primaryColor}>Kapcsolat</Typography>
                        </HashLink>
                    </Stack>
                </Grid>}
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Nyitvatartás</Typography>
                        {Object.keys(open).map((key,i) => <div key={`day_${i}`}>
                            <Typography variant="caption" style={{...primaryColor, marginTop: '5px'}}>{key.toString()}</Typography>
                            <br/>
                            <Typography variant="button" style={{marginTop: '3px'}}>{open[key].toString()}</Typography>
                        </div>)}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Dokumentumok</Typography>
                        <Typography variant="buttonNormal" style={primaryColor}><a style={{color: theme.palette.primary[700]}} target='_blank' rel="noreferrer" href="https://dinobooking.hu/adatvedelem">Adatvédelmi nyilatkozat</a></Typography>
                        {payment_on && <Typography variant="buttonNormal" style={primaryColor}><a style={{color: theme.palette.primary[700]}} target='_blank' rel="noreferrer" href="/adattovabbitasi-nyilatkozat">SimplePay adattovábbítási nyilatkozat</a></Typography>}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <div className="dino-footer-container__service-provider">
                        <Stack direction="row" spacing={{xs: 2}}>
                            <Avatar alt={name?.toUpperCase()} src={baseUrl + logo} sx={{width: '70px', height: '70px', background: theme.palette.primary[200]}}/>
                            <Typography variant="subtitle1" style={{alignSelf: 'center'}}>{name}</Typography>
                        </Stack>
                    </div>
                    <div className="dino-footer-container__own-logo">
                        <Stack direction="row" spacing={{xs: 2}}>
                            <Typography variant="body2" style={{alignSelf: 'center', ...primaryColor}}>POWERED BY</Typography>
                            <ColoredImage color={theme.name}/>
                        </Stack>
                        {payment_on && <img src={simpleLogo} onClick={() => window.location.href = 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'} style={{cursor: 'pointer'}} alt="SimplePay logo" width='150px'/>}
                    </div>
                </Grid>
            </Grid>

        </div>
    </div>

}

export default DinoFooter