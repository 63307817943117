import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./ComponentDef";

const dinoGrey = {
    '900': '#0B0E0F',
    '800': '#1C2427',
    '700': '#38474D',
    'A700': '#38474D',
    '600': '#536B74',
    '500': '#6F8E9A',
    '400': '#8CA5AE',
    'A400': '#8CA5AE',
    '300': '#A9BBC2',
    '200': '#C5D2D7',
    'A200': '#C5D2D7',
    '100': '#E2E8EB',
    'A100': '#E2E8EB',
    '50': '#F1F4F5',
    '20': '#F3F5F7'
}

const greyTheme = createTheme({
        components: components,
        name: 'grey',
        palette: {
            primary: dinoGrey,
            secondary: dinoGrey,
        },
        typography: typography
    },
    huHU)

export default greyTheme