import {useTheme} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const CircleButton = ({icon, inverted = false, onClick, ...rest}) => {
    const baseTheme = useTheme()
    const theme = createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: inverted ? '#fff' : baseTheme.palette.primary.main,
                        backgroundColor: inverted ? baseTheme.palette.primary.main : '#fff',
                        '&:hover': {
                            color: baseTheme.palette.primary.main,
                            background: baseTheme.palette.primary[200],
                            border: `2px solid ${baseTheme.palette.primary.main}`
                        },
                        border: '2px solid',
                        width: '65px',
                        height: '65px'
                    }
                }
            }
        },
    })

    return <IconButton style={{alignSelf: 'center'}} {...rest} theme={theme} aria-label="next" size="large" onClick={onClick}>{icon}</IconButton>
}

export default CircleButton