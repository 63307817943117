import React from 'react'

const Maps = ({embed}) => {
    return <iframe src={embed}
                   className='dino-maps'
                   style={{border: 0}}
                   allowFullScreen=""
                   loading="lazy"
                   referrerPolicy="no-referrer-when-downgrade"/>
}

export default Maps