import Typography from "@mui/material/Typography";
import {Grid, Stack, useTheme} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";
import PropTypes from "prop-types";


const Colleagues = ({colleagues, heading, text}) => {
    const {baseUrl} = useContext(ConfigContext)
    const theme = useTheme()

    return <div className="colleagues-container" id="colleagues">
        <div className="colleagues-container__content">
            <Stack>
                <Typography variant='headline2' color={{color: theme.palette.primary[700]}} gutterBottom>{heading}</Typography>
                <Typography variant='subtitle1' gutterBottom style={{marginBottom: '48px', color: '#6F8E9A'}}>{text}</Typography>
                <Grid container>
                    {colleagues.map((c, i) =>
                        <Grid item
                              key={`coll_${i}`}
                              xs={6}
                              xl={3}>
                            <Stack>
                                <Avatar alt={c.first_name}
                                        src={baseUrl + c.profile_picture}
                                        sx={{width: 120, height: 120, background: theme.palette.primary[200]}}
                                        style={{margin: '8px auto'}}/>
                                <Typography variant='subtitle1' style={{color: '#1C2427', textAlign: 'center'}} gutterBottom>
                                    {c.last_name} {c.first_name}
                                </Typography>
                                <Typography align="center" variant='subtitle2' style={{color: theme.palette.primary[600], textTransform: 'capitalize'}}>
                                    {c?.title}
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </Stack>
        </div>

    </div>

}

Colleagues.propTypes = {
    colleagues: PropTypes.arrayOf(PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        profile_picture: PropTypes.string,
        title: PropTypes.string,
    })),
    text: PropTypes.string
};

export default Colleagues
