import Base from "./Base";
import {Stack, Typography} from "@mui/material";
import useCallDataApi from "../hooks/data";
import {useEffect, useState} from "react";
import {getSubdomain} from "../utils/Utils";
import Loading from "../components/Loading";

const Simple = () => {
    const {getData: fetchBusiness} = useCallDataApi('sp-businesses')
    const [business, setBusiness] = useState({})
    const [loading, setLoading] = useState(true)

    const getBusiness = () => {
        const subdomain = getSubdomain()
        fetchBusiness(subdomain)
            .then(r => setBusiness(r))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getBusiness()
    }, [])
    return <Base>
        <div className='main' style={{minHeight: '50vh'}}>
            <div style={{background: '#dadada', borderRadius: '16px', margin: '20px auto', width: '80%', padding: '30px'}}>
                <Stack spacing={2}>
                    <Typography variant='headline1'>SimplePay adattovábbítási nyilatkozat</Typography>
                    <Typography variant='body1'>Tudomásul veszem, hogy a {business.name} (székhely: {business?.country?.code} {business.zip} {business.city} {business.address})
                        adatkezelő által a(z) {business.subdomain}.dinobooking.hu felhasználói adatbázisában tárolt alábbi személyes
                        adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által
                        továbbított adatok köre az alábbi: név, e-mail cím, számlázási cím. Az adatfeldolgozó által végzett
                        adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az
                        alábbi linken tekinthető meg: <a href="https://simplepay.hu/vasarlo-aff" target='_blank'>https://simplepay.hu/vasarlo-aff</a></Typography>
                </Stack>
            </div>

        </div>

        <Loading isLoading={loading}/>
    </Base>
}

export default Simple