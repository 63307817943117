import {Stack} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../context/ReservationContext";
import PersonalData from "./PersonalData";
import BillingData from "./BillingData";
import PaymentData from "./PaymentData";
import QuestionData from "./QuestionData";
import OtherData from "./OtherData";
import ExtraItemData from "./ExtraItemData";


const FillData = () => {
    const {questions, selected, extraItems, paymentData} = useContext(ReservationContext)

    return <Stack spacing={{xs: 2}}>
        <PersonalData/>
        {(paymentData?.payment === 'simple' || selected?.service?.mandatory_billing_details !== 'none') && <BillingData/>}
        <PaymentData/>
        {questions?.length !== 0 && <QuestionData/>}
        {extraItems?.length !== 0 && <ExtraItemData/>}
        <OtherData/>
    </Stack>
}

export default FillData