import {createContext} from "react";
import {getSubdomain} from "../utils/Utils";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {
    const subdomain = getSubdomain()

    const contextData = {
        baseUrl: process.env.REACT_APP_BASE_URL,
        // baseUrl: 'https://back.dinobooking.hu',
        subdomain: subdomain,
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}