import {Button, Stack, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useContext, useEffect, useState} from "react";
import ConfigContext from "../context/ConfigContext";
import CircleButton from "../components/CircleButton";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import ReservationContext from "../context/ReservationContext";

const Category = ({category}) => {
    const {baseUrl} = useContext(ConfigContext)
    return <div style={{display: 'flex', gap: '20px'}}>
        <Avatar src={baseUrl + category?.picture} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
        <div className="text-container">
            <Typography align="left" variant="subtitle1">{category.name}</Typography>
            <Typography align="left" variant="body2">{category.description}</Typography>
        </div>
    </div>
}

const SelectCategory = () => {
    const {getData} = useCallDataApi('sp-categories')
    const {subdomain} = useContext(ConfigContext)
    const {next, selected} = useContext(ReservationContext)
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)

    const theme = useTheme()
    const history = useHistory()

    useEffect(() => {
        getData(`get_for_business/?business=${subdomain}`)
            .then(r => setCategories(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buttonStyle = {
        color: theme.palette.primary[500],
        border: `2px solid ${theme.palette.primary[500]}`,
        display: {xs: 'flex', sm: 'flex', md: 'none'},
        alignSelf: 'center',
        '&:hover': {
            color: theme.palette.primary[500],
            border: `2px solid ${theme.palette.primary[500]}`,
            background: theme.palette.primary[100]
        }
    };

    const proceed = (c) => {
        history.push({
            pathname: '/book',
            search: `?c=${c.id}`
        })
        next({...selected, category: c})
    }

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            {categories.map((c, i) => <Box className="data-select" key={`data_${i}`}>
                    <Stack direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}} spacing={{xs: 2}} sx={{justifyContent: 'space-between'}}>
                        <Category category={c}/>
                        <Button size='large' variant='outlined' endIcon={<NavigateNextIcon/>} sx={buttonStyle} onClick={() => proceed(c)}>Kiválasztom</Button>
                        <CircleButton sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}} icon={<NavigateNextIcon/>} onClick={() => proceed(c)}/>
                    </Stack>

                </Box>)}
        </Stack>

        <Loading isLoading={loading} />
    </div>
}
export default SelectCategory