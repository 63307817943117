import {useEffect, useState} from "react";
import DinoHero from "../components/DinoHero";
import DinoCarousel from "../components/DinoCarousel";
import Services from "../components/Services";
import Colleagues from "../components/Colleagues";
import DinoContact from "../components/DinoContact";
import Maps from "../components/Maps";
import {getSubdomain} from "../utils/Utils";
import Base from "./Base";
import useCallDataApi from "../hooks/data";
import {useHistory} from "react-router-dom";
import Loading from "../components/Loading";

const ServiceProvider = () => {
    const {getData: fetchHomePageImages} = useCallDataApi('sp-home-screen-images')
    const {getData: fetchColleagues} = useCallDataApi('sp-colleagues')
    const {getData: fetchServices} = useCallDataApi('sp-services')
    const {getData: fetchBusinesses} = useCallDataApi('sp-businesses')

    const [businessData, setBusinessData] = useState({});
    const [homePageImages, setHomePageImages] = useState([]);
    const [colleagues, setColleagues] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    const subdomain = getSubdomain()
    const history = useHistory()

    useEffect(() => {
        const getData = async () => {
            const hpi = fetchHomePageImages(`get_for_business/?business=${subdomain}`)
            const coll = fetchColleagues(`get_for_business/?business=${subdomain}`)
            const serv = fetchServices(`get_for_business/?business=${subdomain}`)
            const bus = fetchBusinesses(subdomain)
            const [h, c, s, b] = await Promise.all([hpi, coll, serv, bus])
            if (h) setHomePageImages(h)
            if (c) setColleagues(c)
            if (s) setServices(s)
            if (b) {
                setBusinessData(b)
                document.title = b.public_name
                if (b?.only_links) {
                    history.push('/book')
                }
            }
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))

    }, [])

    return (
        <Base>
            <div className="inline-hero" id="home">
                <DinoHero heading={businessData?.hero_heading}
                          description={businessData?.hero_text}
                          style={{width: '100%'}}/>
                <DinoCarousel images={homePageImages}/>
            </div>
            <Services services={services}
                      heading={businessData?.category_heading}
                      text={businessData?.category_text}/>
            <Colleagues colleagues={colleagues}
                        heading={businessData?.colleagues_heading}
                        text={businessData?.colleagues_text}/>
            <div className="inline-hero" id="contact-us">
                <DinoContact heading={businessData?.contact_heading}
                             description={businessData?.contact_text}
                             email={businessData?.public_email}
                             phone={businessData?.public_phone}
                             address={businessData?.public_address}
                             facebook={businessData?.facebook}
                             instagram={businessData?.instagram}
                             youtube={businessData?.youtube}
                             twitter={businessData?.twitter}
                             style={{width: '100%'}}/>
                <Maps embed={businessData?.maps}/>
            </div>
            <Loading isLoading={loading} />
        </Base>);
}

export default ServiceProvider