import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';

function useCallDataApi(model) {
    const {unauthenticatedHeaders} = useAuthHeader()
    const {baseUrl} = useContext(ConfigContext)
    const url = baseUrl + '/' + model;

    const unauthenticatedAPI = axios.create({
        baseURL: url,
        headers: unauthenticatedHeaders
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: unauthenticatedHeaders
    })

    // ************** UNAUTHENTICATED STUFF **************

    const postData = useCallback((slug, data) => {
            return unauthenticatedAPI.post(slug, JSON.stringify(data))
                .then(r => r)
        },
        [unauthenticatedAPI]
    );

    const getData = useCallback((id = '') => {
            return unauthenticatedAPI.get(id)
                .then(r => r?.data)
        },
        [unauthenticatedAPI]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return  formDataAPI.post("/", data)
                .then(r => r?.data)
                .catch(error => error)
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(`${id}/`, data)
                .then(r => r?.data)
                .catch(error => error)
        },
        [formDataAPI]
    );

    return {
        postData,
        getData,
        formDataCreateData,
        formDataUpdateData,
    };
}

export default useCallDataApi;